"use strict";

// *** ON INIT ***

var ntvInit = function () {
  lazySizes.init();

  
	if(ntv.pageInfo.pmode){
		$("body").addClass("pmode");
	}
	
	
	if(ntv.urlParams.service!="webview"){
		authCom();
	}
	

  // set iframe src if there is an extra src for xs
  $("iframe[data-src-xs]").each(function () {
    $(this).attr(
      "src",
      getBreakpoint() == "xs" ? $(this).data("src-xs") : $(this).data("src")
    );
  });

  var $vwdContainer = $(".VWDcontent"); // container for vwd-embed
  if ($vwdContainer.length > 0) {
    vwdEmbed($vwdContainer);
  }

  partnerSliders();
  bnewsSliders();
  moderatorsSliders();
  deviceDetection();
  boerseNav();
  
  // clickhander für consentlayer in der Datenschutzerklärung
  if(ntv.pageInfo.article && ntv.pageInfo.article.id && ntv.pageInfo.article.id=="15745191"){
	  datenschutzerklaerung();
  }
  
  
  trackScrollHome();

  trackMenu();
  
  trackTabSwitch();
  
  if (ntv.urlParams.testViTeaser) {
    videoTeaserPreview();
  }

};

//*** ON DOCUMENT READY ***

$(document).ready(function () {
  // We detect what kind of content we have here
  // TODO: check all at once?
  var $article = $(".article"); // article or video-detail pages
  var $author = $(".author"); // author page
  var $copyElement = $('.article__share img[src*="link"]'); // copy to clipboard buttons (der Tag page)
  var $gotop = $(".gotop"); // go to top buttons (der Tag page)
  var $poll = $(".poll"); // polls (inline and page)
  var $vwdContainer = $(".VWDcontent"); // container for vwd-embed
  //var $playerwrapper = $('#playerwrapper'); // container for videoplayer
  var $stickyVideoWrapper = $(".sticky-video-wrapper"); // container for videoplayer
  var $shareContainer = $(".article__share"); // container for share icons
  var $tv = $(".tv");
  var $weather = $(".weather"); // weather widget
  var $stockTable = $(".stock-table"); // stock tables
  var $themeCat = $(".theme__cat"); // theme video or gallery page dropdown
  var $quiz = $(".quiz"); // quiz
  var $bwinContainer = $(".sport.bwin"); // football-ticker with bwin
  var $rangeslider = $(".rangeslider"); // for styling input type range
  var $audioplayer = $(".auplayer");

  window.$body = $("body"); // Body

  menu();

  if (
    $("#header__nav__timezones").length > 0 &&
    (getBreakpoint() == "lg" || getBreakpoint() == "md")
  ) {
    timeZones();
  }

  popup();
  gallery();
  contact();
  newsletter();
  infographicSwitch();

  if ($article.length > 0) {
    article($article);
  }


	

  if ($stickyVideoWrapper.length > 0) {
	if(ntv.urlParams.foundationPlayer || ntv.foundationPlayer){
		stickyVideoFoundation($stickyVideoWrapper);
	}else{
		stickyVideoHandler($stickyVideoWrapper);
	}
  } 


/*
  if ($stickyVideoWrapper.length > 0 && !ntv.urlParams.foundationPlayer) {
	stickyVideoHandler($stickyVideoWrapper);
  }
*/


if(ntv.pageInfo.article && ntv.pageInfo.article.type && ntv.pageInfo.article.type=='container' && $(".day").length > 0  ){
	adsDayTeaser();
}
  /*
  if ($author.length > 0) {
    author($author);
  }
 */

  if ($quiz.length > 0) {
    quiz($quiz);
  }
  
  
  if ( $rangeslider.length > 0 ) {
    prepareRangeSlider( $rangeslider );
  }
  
  if ( $audioplayer.length > 0 ) {
    audioPlayer($(".auplayer"));
  }
  

  if ($copyElement.length > 0) {
    clipboardHandler('.article__share img[src*="link"]');
  }
  
  if ($(".article__share__copy img").length > 0) {
    clipboardHandler('.article__share__copy img');
  }
  
  if ($(".article__share__copy span").length > 0) {
    clipboardHandler('.article__share__copy span');
  }

  if ($gotop.length > 0) {
    goTop($gotop);
  }

  if ($poll.length > 0) {
    poll();
  }
  
  if ($(".sparwelt-neu").length > 0 && !ntv.pageInfo.pmode) {
	    addSparweltTeaser($(".sparwelt-neu"));
  }

  if ( $(".lotto").length > 0 ){
	  renderLotto($(".lotto"));
  }



  if ($tv.length > 0) {
    tvProg($tv);
  }

  if ($weather.length > 0) {
    weather($weather);
  }

  if ($stockTable.length > 0) {
    stockTable($stockTable);
  }

  if ($bwinContainer.length) {
    handleBwin($bwinContainer);
  }

  if ($("iframe[data-resize='true']").length > 0) {
    $("iframe[data-resize='true']").iFrameResize();
  }

  if ($(".group--sport-ticker iframe").length > 0) {
    $(".group--sport-ticker iframe").iFrameResize({
      resizedCallback: function (info) {
        var $ifr = $("#" + info.id);
        var $wrapper = $ifr.parent(".group--sport-ticker");
        if (info.height > 20) {
        	$wrapper.removeClass("group--sport-ticker-off");
        }else{
        	$wrapper.addClass("group--sport-ticker-off");
        }
      }
    });
  }

  /*
  if ($themeCat.length > 0) {
    $themeCat.on("click", function (e) {
      var $target = $(e.target);
      $themeCat.toggleClass("active");

      var $themeCatOverlay = $(".theme__cat__overlay");
      if ($themeCatOverlay.length) {
        $themeCatOverlay.remove();
      } else {
        $body
          .append('<div class="theme__cat__overlay"></div>')
          .find(".theme__cat__overlay")
          .on("click", function (e) {
            $themeCat.removeClass("active");
            e.currentTarget.remove();
          });
      }

      if ($target.is("a")) {
        $themeCat.find(".active").removeClass("active");
        $target.parent("li").addClass("active");
      }
    });
  }
  */
  
	if( $(".dropdown").length > 0 ) {
		dropdown($(".dropdown"));
	}

  if ($(".article .article__text > p").length > 0) {
    trackArticleScroll();
  }

  if ($(".teaser--special a").length > 0) {
    trackImageTeaser();
  }

  if ($(".weather-brand").length > 0) {
    trackWeatherBrand();
  }

  if ($(".linklist").length > 0) {
    linklist($(".linklist"));
  }

  if (getBreakpoint() == "xs" && $("#topbanner-mobile").length > 0) {
    mobileTopbanner();
  }
  
    if ($("#prodo-ad").length > 0 || $("#prodo-ad2").length > 0) {
	embedProdo();
  }

  newContentNote();

  browserPush();


  if(ntv.urlParams.service!="webview"){
	// fallback, if sourcepoint not loaded or pur-mode
	if(ntv.pageInfo.pmode){
		handleConsent();
	}else{
		window.setTimeout(function () {
			if (!ntv.consents.info.handleConsentDone) {
				handleConsent();
			}
		}, 3000);
	}
  }


});


// is calling from sourcepoint callback if consentReady and DOMContentLoaded (document.ready) or by fallback
var handleConsent = function (consents) {
	
	var playerStart = function(){
		if(ntv.urlParams.foundationPlayer || ntv.foundationPlayer){
        	ntvVideoplayer($('#playerwrapper'));
        }else{
			startNtvVideoplayerNew($('#playerwrapper'));
		}
	}

  if (!ntv.consents.info.handleConsentDone) {

    if ($('#playerwrapper').length > 0) {
      if (consents === undefined) {
		if(ntv.urlParams.foundationPlayer || ntv.foundationPlayer){
        	ntvVideoplayer($('#playerwrapper'));
        }else{
			startNtvVideoplayerNew($('#playerwrapper'));
		}
      } else {
	
        window.addEventListener('AC_player_start', playerStart);
        
		window.setTimeout(function () {
			
			if(ntv.urlParams.foundationPlayer || ntv.foundationPlayer){
				if(!window.ntvPlayer){
					window.removeEventListener('AC_player_start', playerStart);
	        		ntvVideoplayer($('#playerwrapper'));
				}
	        }else{
	        	if ( !(ntvVideoplayer && ntvVideoplayer.player) ){
	        		window.removeEventListener('AC_player_start', playerStart);
					startNtvVideoplayerNew($('#playerwrapper'));
	        	}
			}

		}, 3000);
      }
    }

    if(!ntv.pageInfo.pmode){
    
	    // load AdABundle.top.js + vcc
			var s = document.createElement("script");
			s.async = true;
			s.src = (window.location.search.indexOf('gujAd=1') > -1) ? 'https://www.n-tv.de/stat/molten/preview/ntv.de/MoltenBundle.top.js' : 'https://www.n-tv.de/stat/molten/live/ntv.de/MoltenBundle.top.js';
			s.onerror = function() {
				window.dispatchEvent( new CustomEvent('AC_player_start') );
			};
			(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0] || document.getElementsByTagName('script')[0].parentNode).insertBefore(s,null);
			
			var s2 = document.createElement("script");
			s2.async = true;
			s2.src = 'https://bilder-a.akamaihd.net/ip/vcc/vcc.min.js?site=ntv';
			(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0] || document.getElementsByTagName('script')[0].parentNode).insertBefore(s2,null);

	}


    evalConsents(consents);
    embedVendors();

    if(consents){
    
	    var cookieExpireTime = 365 * 24 * 60 * 60 * 1000 + new Date().getTime();
	    var cookieExpireDate = new Date(cookieExpireTime);
	    document.cookie =
	      "consentAvailable=true; expires=" +
	      cookieExpireDate +
	      "; path=/; domain=n-tv.de";
    
    }

  }

  ntv.consents.info.handleConsentDone = true;

};

//*** ON LOAD ***

$(window).on("load", function () {
  if (ntv.chartPage && ntv.chartPage.arbitrageId) {
    writeLastChartPagesCookie(ntv.chartPage.arbitrageId);
  }
});

//*** ON EVENTS ***

/*	we use lazysizes for detect, if an image from a slideris near/in the viewport
  then we have to initialize the slider

  and for fit image-source before lazysizes transform the img-tag
*/

document.addEventListener("lazybeforeunveil", function (e) {
  //slider
  var $image = $(e.target);

  if ($image.parents(".slider__slides").length) {
    var $slider = $image.parents(".slider__slides");
    if (!$slider.data("slider_id")) {
      $slider.attr("data-slider_id", e.timeStamp);
      initDefaultSlider($slider);
    }
  }

  if ($image.parents(".slider__slides--image").length) {
    var $slider = $image.parents(".slider__slides--image");
    if (!$slider.data("slider_id")) {
      $slider.attr("data-slider_id", e.timeStamp);
      initImageSlider($slider);
    }
  }

  if ($image.parents(".sidebar__group--slider").length) {
    var $sidbarGroup = $image.parents(".sidebar__group--slider");
    var $slider = $sidbarGroup.find(".sidebar__group__wrapper");

    if ($slider.length) {
      if (!$slider.data("slider_id")) {
        $slider.attr("data-slider_id", e.timeStamp);
        initSidebarSlider($slider);
      }
    }
  }

  //fit images
  if ($image.data("base") && $image.data("name")) {
    fitImgSrc($image);
  }
});
