'use strict';

// cbc player doku
// https://confluence.netrtl.com/display/NGVOD/API+Dokumentation+v5#APIDokumentationv5-Configuration

// bitmovin untertitel doku
// https://bitmovin.com/docs/player/tutorials/how-to-add-external-subtitles-to-the-bitmovin-player


//global test video untertitel

if(ntv.urlParams.testSubtitle){
	localStorage.setItem('testSubtitle', 'true');
}

if(ntv.urlParams.disableTestSubtitle){
	localStorage.removeItem('testSubtitle');
}

if (localStorage.getItem("testSubtitle") == "true") {
	$("body").addClass("test-ut");
}







//CBC Video Player
var ntvVideoplayer;
var $ntvPlayerwrapper = $('#playerwrapper'); // container for videoplayer






/*



if ( $ntvPlayerwrapper.length > 0 && (!ntv.consents.info.isViewBeforeConsent || ntv.isPreview) ){
	$(document).ready(function () {
			window.setTimeout(
					function () {
						if ( !isPlayerActive() ){
					    	console.log("*** start videoplayer fallback ***");
					    	startNtvVideoplayerNew($ntvPlayerwrapper);
						}
					},
					ntv.isPreview?0:3000
	 		);
	});
}


var handleVideoplayer = function(){
	if ( $ntvPlayerwrapper.length > 0 && !isPlayerActive() ) {
		startNtvVideoplayerNew($ntvPlayerwrapper);
	}
};
*/




var startNtvVideoplayerNew = function( $container ) {
	
	
	
	var isPlayerActive = function(){
		
		if ( ntvVideoplayer && ntvVideoplayer.player ){
			return true;
		}else{
			return false;
		}
		
	};
	

var videoSetup = $container.data( 'player' ).setup;


var playerConf = {
	page: {
		controls: {
            jsUrl: resourceBaseUrl + '/adaptive/videoplayerNew/bitmovinplayer-ui' + (ntv.urlParams.token?'_v5-12':'') + '.js',
			cssUrl: resourceBaseUrl + '/adaptive/videoplayerNew/bitmovinplayer-ui' + (ntv.urlParams.token?'_v5-12':'') + '.css'
		}

	},
	tracking: {
		offer: videoSetup.tracking.angebot,
		videoService: videoSetup.tracking.videoservice
	},
	features: {
		homad:{
			enabled: ntv.disable.homad?false:true,
			clientConfigUrl: resourceBaseUrl + '/adaptive/videoplayerNew/homadClientConfig.json'
		}
	},
	user: {
		jwt: "na"
	},
	isLivestream: videoSetup.livestream || false, //check
	unsupportedAdvertisingConfig: window.$AC && $AC.getSmartClientCoreAdvertisingConfig?$AC.getSmartClientCoreAdvertisingConfig():{}
};


if(!ntv.pageInfo.pmode){
	playerConf.tracking.heartbeat = {};
	
	if(ntv.consents.nielsen){
		playerConf.tracking.nielsen={
			vcId: "c05",
			apId: "PD53D3237-E45E-4D89-A8C3-F75FADE7BBA5",
			sfCode: "eu-uat"
		}
	}
}

if(ntv.urlParams.loggingPlayer){
	playerConf.features.logging = {level:ntv.urlParams.loggingPlayer};
}

if(ntv.urlParams.controlsversion){
	playerConf.page.controls.jsUrl = 'https://cdn.static-fra.de/lib/cbc/videoplayer-controls/' + ntv.urlParams.controlsversion + '/dist/shortform/bitmovinplayer-ui.js';
	playerConf.page.controls.cssUrl = 'https://cdn.static-fra.de/lib/cbc/videoplayer-controls/' + ntv.urlParams.controlsversion + '/dist/shortform/bitmovinplayer-ui.css';
}

if(ntv.urlParams.playertest){
	playerConf.page.controls.jsUrl = resourceBaseUrl + '/adaptive/videoplayerNew/bitmovinplayer-ui_test.js';
	playerConf.page.controls.cssUrl = resourceBaseUrl + '/adaptive/videoplayerNew/bitmovinplayer-ui_test.css';
}

ntvDebug("*** playerConf ***");
ntvDebug(playerConf);


var videoConf = {
	meta: {
	    id: videoSetup.source.videoId,
	    length: {inSeconds: videoSetup.source.length},
	    title: videoSetup.source.title,
	    ivw: videoSetup.source.ivw,
	    payStatusCode: 0,
	    startTypeCode: videoSetup.source.startType || 0 //check
	},
	behavior: {
		autoplay: videoSetup.playback && videoSetup.playback.autoplay==false?false:true //check
	},
	videoSource: {
		streams: {}
	},
	advertising: {
		privMode: ntv.pageInfo.pmode?true:false,
		playAds: { 
			preroll: ntv.pageInfo.pmode?false:videoSetup.advertising.playPreroll,
			midroll: false,
			postroll: false
		},
		shortFormLimit: {inSeconds: 179},
		noAdsLimit: {inSeconds: 29},
		specialAds: {
			  companionAds: false,
			  vpaidAds: false
			  //vpaidAds: ntv.pageInfo.pmode?false:true
		},
		adCall: {
			category: videoSetup.advertising.adCall.category,
			fixParams: ntv.consents.info.tcString?["consent=" + ntv.consents.info.tcString]:["opt=out"],
			referrerUrl: window.location.protocol + '//' + window .location.hostname //check
		}
	}

};


if(videoSetup.source.poster && videoSetup.source.poster!="") videoConf.videoSource.poster=videoSetup.source.poster;
if(videoSetup.source.progressive) videoConf.videoSource.streams.progressiveUrl=videoSetup.source.progressive;
if(videoSetup.source.hls) videoConf.videoSource.streams.hlsUrl=videoSetup.source.hls;
if(videoSetup.source.dash) videoConf.videoSource.streams.dashUrl=videoSetup.source.dash;
if(videoSetup.source.drm){

	videoConf.videoSource.drm = {
        playready: {
               LA_URL: 'https://playready-core.n-tv.de/playready/api/RightsManager.asmx'
       },
       widevine: {
               LA_URL: 'https://widevine.n-tv.de/index/proxy'
        },
       fairplay: {
			LA_URL: 'https://fairplay.n-tv.de/Fairplay/GetLicense',
			certificateURL: 'https://fairplay.n-tv.de/Certificate/RTLiDe_cert.cer',
			headers: {},
			prepareMessage: function (event, session) {
				return 'spc=' + encodeURIComponent(event.messageBase64Encoded) + '&' + session.contentId;
			},
			prepareContentId: function (contentId) {
				var pattern = 'skd://fairplay.n-tv.de?';
				var parameters;
				var idx;

				idx = contentId.indexOf(pattern);
				if (idx > -1) {
					parameters = contentId.substring(idx + pattern.length);
					parameters = parameters.replace(/variantid/gi, 'variantId');
					return parameters;
				} else {
					return '';
				}
			}
       }
	};
}



var trackingDone = false;



var showGeoBlockingInfo = function(){
	ntvVideoplayer.destroy();
	$container.removeAttr('class');
	//$container.css('background','rgb(22, 32, 49)');
	$container.html('<span class="article__media__error"><p>Leider ist dieses Video nicht verfügbar. Grund könnte Ihr aktueller Standort sein.</p><p>Wir freuen uns aber, Sie als Leser informativ und kurzweilig auf dem Laufenden halten zu können.</p></span>');
	
	//ntvVideoplayer.handleContentBlockDetect('<span class="article__media__error">Leider ist dieses Video nicht verfügbar. Grund könnte Ihr aktueller Standort sein.<br/>Wir freuen uns aber, Sie als Leser informativ und kurzweilig auf dem Laufenden halten zu können.</span>');
};


var handlePlayerEvents = function(event){
	
	if( event.type!="onTimeChanged" && event.type!="onContentTimeChanged" ){
		
		ntvDebug("*** videoEvent ***");
		ntvDebug(event);
		
	}
	
	
	switch(event.type) {
	case 'onError':
		/*
		if( !ntvVideoplayer.isAd() && event.data && event.data.statusCode && event.data.statusCode==403 ){
			showGeoBlockingInfo();
		}
		*/
		/* code 1400 = NETWORK_ERROR
		 	code: 1400
			data: undefined
			name: "NETWORK_ERROR"
			timestamp: 1636280169152
			type: "onError"
		 */
		if( !ntvVideoplayer.isAd() ){
			if( (event.data && event.data.statusCode && event.data.statusCode==403) || event.code==1400 ){
				showGeoBlockingInfo();
			}
		}
		break;
	case 'onPlaying':
		window.dispatchEvent( new CustomEvent('videoplayerPlaying') );
		if( !trackingDone ) {
			ntvTracking({
				trackIVW: true,
				trackingSet: $container.data( 'trackingSet' )
			});
			trackingDone = true;
		}
		$("#playerwrapper .bitmovinplayer-poster").css({ 'display': 'none' });
		$("#playerwrapper #bitmovinplayer-video-playerwrapper").css({ 'visibility': 'visible' });
		break;
	case 'onPaused':
		window.dispatchEvent( new CustomEvent('videoplayerPaused') );
		break;
	case 'onAdStarted':
		gaEvent({
			eventCategory: 'videomessung',
			eventAction: 'Preroll',
			eventLabel: 'Ad-start'
		});
		dataLayer.push({
			'event': 'ga4_event' ,
			'eventcategory': 'videomessung',
			'eventaction': 'Preroll',
			'eventlabel': 'Ad-start'
		});
		break;
	case 'onContentStart':
		gaEvent({
			eventCategory: 'videomessung',
			eventAction: 'Content',
			eventLabel: 'Content-Start'
		});
		dataLayer.push({
			'event': 'ga4_event' ,
			'eventcategory': 'videomessung',
			'eventaction': 'Content',
			'eventlabel': 'Content-Start'
		});
		break;
	case 'onContentEnd':
		gaEvent({
			eventCategory: 'videomessung',
			eventAction: 'Content',
			eventLabel: 'Content-Ende'
		});
		dataLayer.push({
			'event': 'ga4_event' ,
			'eventcategory': 'videomessung',
			'eventaction': 'Content',
			'eventlabel': 'Content-Ende'
		});
		break;
	case 'onSessionEnd':
		
		window.dispatchEvent( new CustomEvent('videoplayerSessionEnd') );
		
		 if( $container.data('playlist') && $container.data('playlist').ajaxUrl && $container.data('playlist').videoIds.length > 0 ){
			 
			var playlistSetup = $container.data('playlist');
			 
			var ajaxUrl = playlistSetup.ajaxUrl + "&ctxArt=" + playlistSetup.videoIds[0];
		 	
		 	$container.data('playlist').videoIds.shift();

			$.ajax({
				url: ajaxUrl,
				type: "GET"
			})
			.done( function( data, textStatus, jqXHR ) {
				$(".ajax-wrapper", ".article--video").html(data);
				window.dispatchEvent( new CustomEvent('videoplayerNewVideo') );
				startNtvVideoplayerNew( $("#playerwrapper") );

			})
			.fail( function( jqXHR, textStatus, errorThrown ) {
				//ntv.log( jqXHR, textStatus, errorThrown );
			});

		 }else{

			 if(videoConf.videoSource.poster){
				 $("#playerwrapper .bitmovinplayer-poster").css({ 'display': 'block', 'background-image': 'url(' + videoConf.videoSource.poster + ')', 'visibility': 'visible' });
			 }

		 }
		break;
 }
	

};

ntvDebug("*** videoConf ***");
ntvDebug(videoConf);


/*untertitel && testvar localstorage*/
if(localStorage.getItem("testSubtitle") == "true" && videoSetup.utUrl){
	
	var $videoTag = $("video", $container);
	$videoTag.attr("crossorigin","anonymous");
	$videoTag.append('<track src="' + videoSetup.utUrl + '" label="Untertitel" kind="subtitles" srclang="de" default>');
	$videoTag.css("visibility", "visible");
	
	
}else{


	if( isPlayerActive() ){
		
		if(videoSetup.livestream!=true){
			if(window.$AC) $AC.setTargeting("vcd",videoSetup.source.length);
		}
		videoConf.advertising.adCall.tags = window.$AC?[$AC.getSXPKeys()]:[];
		ntvVideoplayer.loadVideo(videoConf);
	
	}else{
		
		$container.html( '' );
		ntvVideoplayer = CBCVideoplayer.create($container.attr( 'id' ), playerConf);
		CBCVideoplayer.onEvent( $container.attr('id'), handlePlayerEvents );
		
		if(videoSetup.livestream!=true){
			if(window.$AC) $AC.setTargeting("vcd",videoSetup.source.length);
		}
		
		videoConf.advertising.adCall.tags = window.$AC?[$AC.getSXPKeys()]:[];
		videoConf.advertising.adCall.fixParams = ntv.consents.info.tcString?["consent=" + ntv.consents.info.tcString]:["opt=out"];
	
		ntvDebug("*** initLoadVideo ***");
		ntvVideoplayer.loadVideo(videoConf);
	
	}
}




};



